import { format } from 'date-fns'

export function formatDuration(ms: number) {
    const totalSeconds = Math.floor(ms / 1000) // Преобразуем миллисекунды в секунды
    const hours = Math.floor(totalSeconds / 3600) // Получаем количество часов
    const minutes = Math.floor((totalSeconds % 3600) / 60) // Получаем количество минут
    const seconds = totalSeconds % 60 // Получаем оставшиеся секунды
    return hours > 0
        ? `${hours.toString().padStart(2, '0')}:${minutes
              .toString()
              .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        : `${minutes.toString().padStart(2, '0')}:${seconds
              .toString()
              .padStart(2, '0')}`
}
