import styles from './index.styles'

import React from 'react'

import { FormControl, FormLabel, Stack, TextField } from '@mui/material'
import { t } from 'i18next'

import { QuestionType } from 'api/feedback/generated'

interface FeedbackSampleProps {
    questions: QuestionType[]
}

const FeedbackSample = ({ questions }: FeedbackSampleProps): JSX.Element => {
    const classes = styles()

    return (
        <Stack className={classes.root}>
            {questions.map(q => (
                <Stack gap="10px" key={q?.id}>
                    <FormLabel component="legend" className={classes.question}>
                        {q?.text}
                    </FormLabel>
                    <FormControl component="fieldset" variant="standard">
                        <TextField
                            className={classes.answer}
                            multiline
                            variant="outlined"
                            value=""
                            placeholder={
                                t(
                                    'feedbackManager.fields.sampleTextPlaceholder'
                                ) as string
                            }
                            disabled
                            minRows={2}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                </Stack>
            ))}
        </Stack>
    )
}

export default FeedbackSample
