import React from 'react'

import { t } from 'i18next'
import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    SxProps,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material'
import {
    Login,
    StorefrontOutlined,
    RoomPreferences,
    AccountCircle,
    SettingsOutlined,
    LogoutOutlined,
} from '@mui/icons-material'
import { useMatch, useNavigate } from 'react-router-dom'

import { Logo, Image } from 'components/common'
import { BrandButton } from 'components/buttons'
import { useMe } from 'hooks/user'
import { useAuth } from 'hooks/auth'
import { TokenStorage } from 'services'
import { appPaths, backofficePaths } from 'routes'
import noAvatar from 'assets/images/unknown_user.png'

interface HeaderProps {
    sx?: SxProps
}

const Header: React.FC<HeaderProps> = ({ sx = {} }: HeaderProps) => {
    const matches700px = useMediaQuery('(max-width:700px)')
    const matches500px = useMediaQuery('(max-width:500px)')
    const { me } = useMe(!TokenStorage.isAuthenticated())
    const { logout } = useAuth()
    const navigate = useNavigate()
    const isHomePage = useMatch('/')

    const handleGoToProfile = () => {
        if (TokenStorage.isAuthenticated()) {
            const path =
                me?.role?.val === 'Coach'
                    ? `../${appPaths.expert(me?.id)}`
                    : `../${appPaths.profile}`

            navigate(path)
        }
    }

    const renderUserActions = () => {
        return (
            <Stack
                gap={matches700px ? '15px' : '25px'}
                direction="row"
                alignItems="center"
                divider={<Divider flexItem orientation="vertical" />}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    divider={<Divider flexItem orientation="vertical" />}
                    sx={theme => ({
                        backgroundColor: 'rgba(24,24,24,0.86)',
                        border: '1px solid rgb(135 133 133 / 50%)',
                        borderRadius: '4px',
                        '& .MuiButtonBase-root': {
                            fontSize: matches700px ? '14px' : '17px',
                            border: 'none',
                            background: 'none',
                            borderRadius: 0,
                            '&:hover': {
                                border: 'none',
                                color: theme.colors.primary,
                                background: 'none',
                            },
                            '& .MuiSvgIcon-root': {
                                fontSize: matches700px ? '20px' : '26px',
                            },
                        },
                        '& .MuiButton-root': {
                            borderRadius: '4px 0 0 4px',
                        },
                        '& .MuiIconButton-root': {
                            border: 'none',
                            background: 'none',
                            borderRadius: '0 4px 4px 0',
                        },
                    })}
                >
                    <Tooltip
                        title={
                            <Typography fontSize="18px">
                                {t('navigation.toProfile')}
                            </Typography>
                        }
                    >
                        {matches500px ? (
                            <IconButton onClick={handleGoToProfile}>
                                <AccountCircle />
                            </IconButton>
                        ) : (
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleGoToProfile}
                                startIcon={<AccountCircle />}
                            >
                                {me?.email}
                            </Button>
                        )}
                    </Tooltip>
                    <Tooltip
                        title={
                            <Typography fontSize="18px">
                                {t('auth.logout')}
                            </Typography>
                        }
                    >
                        <IconButton onClick={logout}>
                            <LogoutOutlined />
                        </IconButton>
                    </Tooltip>
                </Stack>

                {me?.role?.val === 'Coach' && (
                    <Tooltip
                        title={
                            <Typography fontSize="18px">
                                {t('navigation.toBackoffice')}
                            </Typography>
                        }
                    >
                        <IconButton
                            edge="start"
                            onClick={() =>
                                navigate(`../${backofficePaths.backoffice}`)
                            }
                        >
                            <RoomPreferences
                                sx={{
                                    fontSize: matches700px ? '25px' : '35px',
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                )}
            </Stack>
        )
    }

    const renderGuestActions = () => {
        const path = `../${appPaths.login}?redirectTo=${window.location.href}`

        return (
            <Stack
                gap={matches700px ? '10px' : '25px'}
                direction="row"
                alignItems="center"
                divider={<Divider flexItem orientation="vertical" />}
                sx={theme => ({
                    backgroundColor: 'rgba(24,24,24,0.86)',
                    border: '1px solid rgb(135 133 133 / 50%)',
                    borderRadius: '4px',
                    '& .MuiButtonBase-root': {
                        fontSize: matches700px ? '14px' : '17px',
                        border: 'none',
                        background: 'none',
                        borderRadius: 0,
                        '&:hover': {
                            border: 'none',
                            color: theme.colors.primary,
                            background: 'none',
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: matches700px ? '18px' : '22px',
                        },
                    },
                    '& .MuiButton-root': {
                        borderRadius: '4px 0 0 4px',
                    },
                    '& .MuiIconButton-root': {
                        border: 'none',
                        background: 'none',
                        borderRadius: '0 4px 4px 0',
                    },
                })}
            >
                {matches500px ? (
                    <Tooltip
                        title={
                            <Typography fontSize="18px">
                                {t('auth.login')}
                            </Typography>
                        }
                    >
                        <IconButton onClick={() => navigate(path)}>
                            <Login />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate(path)}
                        startIcon={<Login />}
                    >
                        {t('auth.login')}
                    </Button>
                )}
            </Stack>
        )
    }

    return (
        <Box
            sx={{
                padding: matches700px ? '15px 20px' : '20px 25px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                marginBottom: '33px',
                borderRadius: '8px',
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
                border: '1px solid #222',
                background: 'rgba(0, 0, 0, 0.48)',
                backdropFilter: 'blur(5px)',
                position: 'sticky',
                top: '25px',
                zIndex: 5,
                ...sx,
            }}
        >
            <Stack direction="row" alignItems="center" gap="100px">
                <Logo
                    height={matches700px ? '30px' : undefined}
                    onClick={handleGoToProfile}
                />
            </Stack>
            <Stack
                direction="row"
                alignItems="normal"
                gap="50px"
                sx={{
                    zIndex: 2,
                }}
                divider={
                    <Divider
                        sx={{ borderWidth: '1px', height: 'auto' }}
                        orientation="vertical"
                    />
                }
            >
                {TokenStorage.isAuthenticated()
                    ? renderUserActions()
                    : renderGuestActions()}
            </Stack>
        </Box>
    )
}
export default Header
