import React from 'react'

import { Divider, Stack, Typography, useMediaQuery } from '@mui/material'
import { format } from 'date-fns-tz'

import { FeedbackType } from 'api/feedback/generated'

interface FeedbackPreviewProps {
    feedback: Partial<FeedbackType>
}

const FeedbackPreview = ({ feedback }: FeedbackPreviewProps): JSX.Element => {
    const lessThan700px = useMediaQuery('(max-width:700px)')

    return (
        <Stack
            sx={{
                width: '100%',
                padding: lessThan700px ? 0 : '20px 8px',
                position: 'relative',
            }}
        >
            <Typography
                sx={theme => ({
                    position: 'absolute',
                    top: '-3px',
                    right: '8px',
                    fontSize: 'max(min(2.5vw, 18px), 12px)',
                    color: theme.colors.secondaryLight,
                })}
            >
                {format(new Date(feedback.createdAt), 'dd.MM.yyyy HH:mm', {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                })}
            </Typography>
            <Stack divider={<Divider />} gap="10px">
                {feedback.answers?.edges
                    .map(e => e?.node)
                    .map(a => (
                        <Stack gap="10px" key={a?.id}>
                            <Typography
                                variant="caption"
                                sx={{
                                    fontSize: 'max(min(2.5vw, 17px), 13px)',
                                    lineHeight: 'max(min(2.5vw, 18px), 14px)',
                                    color: '#d5d3d3',
                                }}
                            >
                                {a?.question.text}
                            </Typography>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: '#ffffff',
                                    fontSize: 'max(min(2.5vw, 18px), 14px)',
                                    '& .MuiInputBase-root': {
                                        padding: 0,
                                        '&.Mui-disabled': {
                                            '& textarea': {
                                                '-webkit-text-fill-color': `#bbbbbb !important`,
                                            },
                                            '& fieldset': {
                                                borderColor:
                                                    'transparent !important',
                                            },
                                        },
                                    },
                                }}
                            >
                                {a?.text ? `"${a?.text}"` : '-'}
                            </Typography>
                        </Stack>
                    ))}
            </Stack>
        </Stack>
    )
}

export default FeedbackPreview
