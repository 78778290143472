import { styled } from '@mui/material/styles'

import Dialog from './Dialog'
import Avatar from './Avatar'
import Dropzone from './Dropzone'
import ReactiveDialog from './ReactiveDialog'
import Logo from './Logo'
import SocialsList, { SocialItem, Socials } from './SocialsList'
import ResourceConfirmation, {
    ResourceConfirmationProps,
} from './ResourceConfirmation'
import TextConfirmation, { TextConfirmationProps } from './TextConfirmation'
import CategoryList from './CategoryList'
import FeedbackPreview from './FeedbackPreview'
import FeedbackSample from './FeedbackSample'
import OwnerPanel from './OwnerPanel'
import FilePicker from './FilePicker'

const Image = styled('img')``
const Input = styled('input')``

export {
    Dialog,
    Avatar,
    ReactiveDialog,
    Dropzone,
    Logo,
    SocialsList,
    Socials,
    ResourceConfirmation,
    TextConfirmation,
    CategoryList,
    FeedbackPreview,
    FeedbackSample,
    Image,
    Input,
    OwnerPanel,
    FilePicker,
}

export type { SocialItem, ResourceConfirmationProps, TextConfirmationProps }
