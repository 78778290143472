import React from 'react'

import {
    Box,
    Stack,
    Typography,
    Divider,
    useMediaQuery,
    Tooltip,
    LinearProgress,
} from '@mui/material'
import { Lock, AccessTime, QuestionMarkOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { Image } from 'components/common'
import { LessonType, ModuleType } from 'api/root/generated'
import { appPaths } from 'routes'
import { formatDuration } from '../../../utils/dates.utils'

interface CourseContentListProps {
    items: ModuleType[]
}

const CourseContentList = ({ items }: CourseContentListProps) => {
    const navigate = useNavigate()
    const moreThan1200px = useMediaQuery('(min-width:1200px)')
    const lessThan800px = useMediaQuery('(max-width:800px)')
    const lessThan570px = useMediaQuery('(max-width:570px)')
    const lessThan500px = useMediaQuery('(max-width:500px)')

    const handleGoToWatch = (moduleId: string, videoId: string) => {
        navigate(`../${appPaths.watch(videoId)}`)
    }

    const renderLesson = (lesson: LessonType) => {
        const accessible = lesson.hasAccess
        const available = lesson.isAvailable
        const hasDuration =
            typeof lesson?.duration === 'number' && lesson?.duration > 0
        const hasView = lesson?.view

        const percent = hasDuration ? (lesson.duration as number) / 100 : 0
        const progress = (lesson?.view?.durationMs as number) / percent

        return (
            <Box
                key={lesson.id}
                onClick={() => {
                    if (accessible && available) {
                        handleGoToWatch(lesson.module.id, lesson.id)
                    }
                }}
                sx={theme => ({
                    width: '300px',
                    height: '210px',
                    ...(lessThan800px && {
                        width: '220px',
                        height: '150px',
                    }),
                    ...(lessThan570px && {
                        width: '100%',
                        height: '220px',
                    }),
                    boxShadow: '0 0 7px #000',
                    border: `1px solid transparent`,
                    cursor: 'pointer',
                    position: 'relative',
                    background: '#181818',
                    '&:hover': {
                        border: `1px solid ${theme.colors.primary}`,
                        '& .MuiTypography-root': {
                            background: theme.colors.primary,
                            color: theme.colors.background,
                        },
                    },
                    ...((!accessible || !available) && {
                        filter: 'grayscale(1)',
                        pointerEvents: 'none',
                    }),
                })}
            >
                {hasDuration && (
                    <Box
                        sx={{
                            border: `1px solid #3b3b3b`,
                            background: '#181818cc',
                            position: 'absolute',
                            top: '1%',
                            right: '1%',
                            fontSize: '13px',
                            zIndex: 2,
                            padding: '5px',
                        }}
                    >
                        {formatDuration(lesson?.duration as number)}
                    </Box>
                )}
                {hasView && hasDuration && accessible && available && (
                    <LinearProgress
                        sx={theme => ({
                            position: 'absolute',
                            bottom: '40px',
                            left: 0,
                            right: 0,
                            height: '5px',
                            zIndex: 2,
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: theme.colors.primary,
                            },
                        })}
                        color="secondary"
                        value={progress}
                        variant="determinate"
                    />
                )}
                {!accessible && (
                    <Lock
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            fontSize: '65px',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                )}
                {accessible && !available && (
                    <AccessTime
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            fontSize: '65px',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                )}
                {lesson.videoPreview ? (
                    <Image
                        src={lesson.videoPreview.img520x200 as string}
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                        alt=""
                    />
                ) : (
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        width="100%"
                        sx={{
                            backgroundColor: '#181818',
                        }}
                    >
                        <QuestionMarkOutlined
                            sx={{
                                color: '#4e4e4e',
                                fontSize: '105px',
                            }}
                        />
                    </Stack>
                )}
                <Tooltip
                    title={
                        <Typography fontSize="20px">{lesson.name}</Typography>
                    }
                >
                    <Typography
                        textAlign="right"
                        noWrap
                        fontSize="max(min(1vw, 20px), 14px)"
                        maxWidth="100%"
                        sx={{
                            backgroundColor: 'rgba(0,0, 0, 1)',
                            position: 'absolute',
                            bottom: '0',
                            right: '0',
                            width: '100%',
                            padding: '5px 10px',
                        }}
                    >
                        {lesson.name}
                    </Typography>
                </Tooltip>
            </Box>
        )
    }

    const renderModule = (module: ModuleType) => {
        const lessons = module.lessons.edges.map(e => e?.node) as LessonType[]
        const accessible = module.hasAccess

        return (
            <Stack key={module.id}>
                <Stack
                    sx={{
                        padding: '15px 25px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
                        border: '1px solid #222',
                        background: 'rgba(0, 0, 0, 0.48)',
                    }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="25px"
                >
                    <Stack
                        alignItems="center"
                        divider={<Divider flexItem orientation="vertical" />}
                        gap="25px"
                        direction="row"
                    >
                        <Typography
                            sx={{
                                whiteSpace: 'nowrap',
                                fontSize: '28px',
                                '@media (max-width:1900px)': {
                                    fontSize: '24px',
                                },
                                '@media (max-width:1300px)': {
                                    fontSize: '20px',
                                },
                            }}
                        >
                            {module.name}
                        </Typography>
                        {moreThan1200px && (
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    color: '#cfcfcf',
                                    '@media (max-width:1900px)': {
                                        maxWidth: 'auto',
                                    },
                                    '@media (max-width:1300px)': {
                                        fontSize: '16px',
                                    },
                                }}
                            >
                                {module.description}
                            </Typography>
                        )}
                    </Stack>
                    {!accessible && <Lock />}
                </Stack>
                {lessons.length > 0 && (
                    <Box
                        sx={{
                            padding: '25px',
                            width: '100%',
                            flexGrow: 1,
                            ...(lessThan500px && {
                                padding: '15px',
                            }),
                        }}
                    >
                        <Stack
                            sx={{
                                flexWrap: 'wrap',
                                '@media (max-width:480px)': {
                                    flexWrap: 'nowrap',
                                    flexDirection: 'column',
                                },
                            }}
                            direction="row"
                            gap="35px"
                        >
                            {lessons.map(renderLesson)}
                        </Stack>
                    </Box>
                )}
            </Stack>
        )
    }

    return <Stack gap="30px">{items.map(renderModule)}</Stack>
}
export default CourseContentList
