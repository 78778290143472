export default class PathStorage {
    static ROOT = 'REACT_APP_ROOT'

    static ROOT_API = 'REACT_APP_ROOT_API'

    static PAYMENT_API = 'REACT_APP_PAYMENT_API'

    static AUTH_API = 'REACT_APP_AUTH_API'

    static FEEDBACK_API = 'REACT_APP_FEEDBACK_API'

    static getURL(path: string): string {
        return process.env.NODE_ENV === 'development'
            ? process.env[path]
            : (window as any)[path]
    }

    static site() {
        return PathStorage.getURL(PathStorage.ROOT)
    }

    static root() {
        return `${PathStorage.site()}${PathStorage.getURL(
            PathStorage.ROOT_API
        )}`
    }

    static payment() {
        return `${PathStorage.site()}${PathStorage.getURL(
            PathStorage.PAYMENT_API
        )}`
    }

    static auth() {
        return `${PathStorage.site()}${PathStorage.getURL(
            PathStorage.AUTH_API
        )}`
    }

    static feedback() {
        return `${PathStorage.site()}${PathStorage.getURL(
            PathStorage.FEEDBACK_API
        )}`
    }
}
