import { useDialog } from './index'
import {
    TextConfirmation,
    ResourceConfirmation,
    ResourceConfirmationProps,
    TextConfirmationProps,
} from 'components/common'

const useConfirmationDialog = <T extends { name: string }>() => {
    const { open, close } = useDialog()

    const openConfirmationModal = (
        data: T,
        onSuccess: () => void,
        props?: Partial<ResourceConfirmationProps<T>>
    ) => {
        open({
            component: ResourceConfirmation,
            props: {
                onApprove: () => {
                    onSuccess()
                    close()
                },
                onReject: close,
                data,
                ...props,
            },
            options: {
                onClose: close,
            },
        })
    }

    const openTextConfirmationModal = (
        title: string,
        message: string,
        onSuccess: () => void
    ) => {
        open({
            component: TextConfirmation,
            props: {
                onApprove: () => {
                    onSuccess()
                    close()
                },
                onReject: close,
                title,
                message,
            },
            options: {
                onClose: close,
            },
        })
    }

    return {
        requestConfirmation: openConfirmationModal,
        requestTextConfirmation: openTextConfirmationModal,
        close,
    }
}

export default useConfirmationDialog
