import styles from './index.styles'

import React, { useState } from 'react'

import { t } from 'i18next'
import { Button, Stack, Typography } from '@mui/material'
import classnames from 'classnames'
import { InfoOutlined } from '@mui/icons-material'

import { CourseType, ModuleType, LessonType } from 'api/root/generated'
import { PrimaryButton, SecondaryButton } from '../../buttons'

export interface ResourceConfirmationProps<T> {
    onApprove: () => void
    onReject: () => void
    data: T
    type?: 'lesson' | 'module' | 'course'
}

const ResourceConfirmation = <T extends ModuleType | CourseType | LessonType>({
    onApprove,
    onReject,
    data,
    type,
}: ResourceConfirmationProps<T>): JSX.Element => {
    const classes = styles()

    const renderResource = (
        data: ModuleType | CourseType | LessonType,
        compact = false
    ) => {
        const image =
            // eslint-disable-next-line no-underscore-dangle
            data.__typename === 'ModuleType'
                ? null
                : (data as LessonType).videoPreview?.img150x100
                ? (data as LessonType).videoPreview?.img150x100
                : (data as CourseType).background?.img500x300

        return (
            <Stack
                key={data.id}
                direction="row"
                alignItems="center"
                gap="25px"
                className={classnames(classes.resource, {
                    [classes.compact]: compact,
                })}
            >
                {image && (
                    <img
                        className={classes.preview}
                        src={image as string}
                        alt=""
                    />
                )}
                <Typography className={classes.name}>{data.name}</Typography>
            </Stack>
        )
    }

    const renderNestedContent = (data: ModuleType | CourseType) => {
        const items: (ModuleType | LessonType)[] =
            type === 'course'
                ? ((data as CourseType).modules?.edges.map(
                      n => n?.node
                  ) as ModuleType[])
                : ((data as ModuleType).lessons?.edges.map(
                      n => n?.node
                  ) as LessonType[])

        return (
            <Stack gap="5px">{items?.map(i => renderResource(i, true))}</Stack>
        )
    }

    return (
        <Stack className={classes.root} gap="25px">
            <Typography className={classes.title}>
                {t('confirmation.title')}
            </Typography>
            {renderResource(data)}
            <Stack direction="row" alignItems="center" gap="15px">
                <InfoOutlined
                    color="warning"
                    sx={{
                        fontSize: '35px',
                    }}
                />
                <Typography className={classes.caption}>
                    {t('confirmation.caption')}
                </Typography>
            </Stack>
            {type !== 'lesson' &&
                renderNestedContent(data as CourseType | ModuleType)}
            <Stack justifyContent="flex-end" gap="15px" direction="row">
                <Button
                    color="error"
                    variant="outlined"
                    // sx={{ color: '#964b4c' }}
                    onClick={onReject}
                >
                    {t('confirmation.reject')}
                </Button>
                <Button className={classes.approveButton} onClick={onApprove}>
                    {t('confirmation.approve')}
                </Button>
            </Stack>
        </Stack>
    )
}
export default ResourceConfirmation
